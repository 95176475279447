<template>
  <div>
    <!-- /.modal -->
    <div class="content-header">
      <div class="container-fluid">
        <div class="row mb-3">
          <div class="col-sm-6" style="padding-left: 26px !important;">
            <h1 class="m-0 text-dark" style="font-size: 2em;">
              Pengawasan Vending Machine
            </h1>
          </div>
          <!-- /.col -->
          <div class="col-sm-6" :hidden="this.userRole == 'ROLE_MERCHANT'">
            <ol class="breadcrumb float-sm-right ">
              <!-- <li>
                <button
                  type="button"
                  class="btn btn-block btn-outline-primary btn-lg"
                >
                  Export
                </button>
              </li> -->
              <!-- <li class="ml-3">
                <button
                  type="button"
                  class="btn btn-block btn-primary btn-lg"
                  @click="showModal"
                >
                  <span class="fas fa-plus mr-2"></span> Add Vending
                </button>
              </li> -->
            </ol>
          </div>
          <!-- /.col -->
        </div>
        <div class="row">
          <div class="col-sm-12 col-md-9">
          
          </div>
        </div>
        <!-- /.row -->
      </div>
      <!-- /.container-fluid -->
    </div>
    <div v-if="vendingList?.length > 0">
      <div class="row pl-3 pr-3">
        <div
          class="col-lg-3 col-md-3 col-sm-6"
          v-for="items in vendingList"
          :key="items.id"
        >
          <div class="card vm-card-vending">
            <div class="card-header text-neutral">
              <div class="row">
                <div class="col d-flex align-items-center">
                  {{ items.vending_code }}
                </div>
                <div class="col d-flex justify-content-end">
                  <div class="dropdown">
                    <button
                      class="btn btn-sm"
                      type="button"
                      id="dropdownMenuButton"
                      data-toggle="dropdown"
                      aria-haspopup="true"
                      aria-expanded="false"
                    >
                      <span class="fas fa-ellipsis-h text-neutral"></span>
                    </button>
                    <div
                      class="dropdown-menu"
                      aria-labelledby="dropdownMenuButton"
                    >
                      <router-link
                        class="dropdown-item"
                        :to="{
                          name: 'AssignVendingDinamis',
                          query: { 
                            vendingId: items.id
                          },
                          params: {
                            id: items.id,
                            row: items.row_slot ? items.row_slot : 5,
                            col: items.col_slot ? items.col_slot : 8,
                          },
                        }"
                      >
                        Atur Stok <i class="fas fa-chevron-right" style="margin-left: 50px;"></i>
                      </router-link>
                      <span class="dropdown-item" disabled style="cursor: pointer;">
                        Ganti Status:
                      </span>
                      <div @click="save(items.id, value)">
                        <span class="dropdown-item text-success" @click="save(items.id, 'Online')">
                        <span
                            class="fas fa-circle mr-2"
                            style="font-size: 8px;"
                            data-status="online"
                          ></span
                          >Online
                        </span>
                        <span class="dropdown-item text-warning" @click="save(items.id, 'Perbaikan')">
                        <span
                          class="fas fa-circle mr-2"
                          style="font-size: 8px;"
                          data-status="maintenance"
                        ></span
                        >Perbaikan
                        </span>
                        <span class="dropdown-item text-danger" @click="save(items.id, 'Offline')">
                        <span
                            class="fas fa-circle mr-2"
                            style="font-size: 8px;"
                            data-status="offline"
                          ></span
                          >Offline
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
  
            <div class="card-body">
              <div class="vending-title">{{ items.name }}</div>
              <div class="text-primary company-title">
                <span class="fas fa-building mr-2"></span
                >{{ items.name }}
              </div>
            </div>
            <div class="card-footer">
              <div class="row">
                <div class="col-sm-12 col-md-6">
                  <span :class="items.status === 'Online' ? 'badge' : items.status === 'Offline' ? 'badge-offline' : 'badge-maintenance'">{{ items.status }}</span>
                </div>
                <div class="col-sm-12 col-md-6">
                  <!-- For Message -->
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div v-else class="image-empty"> 
      <img src="../../assets/img/emptyMonitorVM.png">
    </div>
  </div>
</template>

<script>
const { console } = window;

import "@assets/scss/card.scss";
export default {
  components: {},
  data() {
    return {
      vendingList: null,
      active: false,
      errorDialog: null,
      errorText: "",
      uploadFieldName: "file",
      error: undefined,
      loading: false,
      text_validation: null,
      searchTerm: "",
      filter: [],
      maxSize: 1024,
      userRole: "",
      form: {
        id: null,
        vending_id: "",
        name: "",
        address: "",
        row_slot: null,
        col_slot: null,
        status: ""
      },
      disabled: false,
      isDetail: true,
    };
  },
  computed: {
    console: () => console,
  },
  mounted() {
    this.userRole = Api.getUserRole();
    this.getData();
  },
  props: {
    value: Object,
  },
  methods: {
    searchVending(e) {
      this.searchTerm = e.target.value;
      this.filter = [
        ["name", "like", this.searchTerm],
        ["or"],
        ["vending.name", "like", this.searchTerm],
      ];
      this.getData();
    },
    
    async getData() {
      await this.Api.get(`/vending`)
        .then((res) => {
          this.vendingList = res.data.content;
          this.vendingList.map((e) => {
            this.form = e
          })
        })
        .catch((e) => {
          console.log(e);
        });
    },
    async save(id, value) {
      this.loading = true;

      this.form.status = value

      try {
        if(value) {
          await this.Api.put(`/vending/${id}`, this.form)
          this.$toast.success("Status berhasil diubah.", {
            position: "bottom-right",
            timeout: 3000,
            closeOnClick: true,
            pauseOnFocusLoss: true,
            pauseOnHover: true,
            draggable: true,
            draggablePercent: 0.6,
            showCloseButtonOnHover: false,
            hideProgressBar: true,
            closeButton: "button",
            icon: true,
            rtl: false
          });
        }
        this.getData()

      } catch (error) {
        
      }
    },
    formatPrice(value) {
      return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
    },
  },
};
</script>

<style lang="sass">
.badge-maintenance
    border: 1px solid #EECEB0
    color: #CD7B2E
    background-color: #FFF9F2
    padding: 3px 10px
    border-radius: 5px
    display: inline-block
    padding: 0.25em 0.4em
    font-size: 75%
    font-weight: 700
    line-height: 1
    text-align: center
    white-space: nowrap
    vertical-align: baseline

.badge-offline
    border: 1px solid #EEB4B0
    color: #CB3A31
    background-color: #FFF4F2
    padding: 3px 10px
    border-radius: 5px
    display: inline-block
    padding: 0.25em 0.4em
    font-size: 75%
    font-weight: 700
    line-height: 1
    text-align: center
    white-space: nowrap
    vertical-align: baseline
</style>